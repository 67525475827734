import React from "react";
import atoms from "../../../atoms";
import { AllProductListProps, BulkInternalTransferProps } from "./interface";
import molecules from "../../../molecules";
import { numberWithCommas } from "../../../../constants/utils";
import { IoIosCloseCircleOutline } from "react-icons/io";

const { Select, Search, Table, CustomImages, Button } = atoms;
const { SearchTab } = molecules;

const Index = ({
    handleSelectStore,
    storeList,
    searchTabContainer,
    handleSearch,
    setPreviewTab,
    previewTab,
    allProductList,
    handleSearchSelect,
    productDataTable,
    handleRemoveProduct,
    handleSubmit,
    selectedStore
}: BulkInternalTransferProps) => {

    const columns = [
        { name: 'Product Type', selector: (row: AllProductListProps) => row?.product_id_display || '-', },
        { name: 'Cover Photo', selector: (row: AllProductListProps) => <CustomImages src={row?.images?.length > 0 && row?.images[0] || ''} />, center: true },
        { name: 'Brand', selector: (row: AllProductListProps) => row?.brand.brand_name || '-', },
        { name: 'Model No.', selector: (row: AllProductListProps) => row?.modal_number || '-', },
        { name: 'Brand Color Code', selector: (row: AllProductListProps) => row?.modal_number, sortable: true },
        { name: 'Glass Size', cell: (row: AllProductListProps) => row?.glass_size || '-', center: true },
        { name: 'With Case', selector: (row: AllProductListProps) => row?.product_with_case ? "true" : " false", },
        { name: 'SRP', selector: (row: AllProductListProps) => `₹${numberWithCommas(parseFloat(Number(row?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Action', selector: (row: any) => row.action },
    ];

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <Select
                        label={"Transfer to "}
                        className={"w-100"}
                        placeholder={"Select Store"}
                        options={storeList}
                        onChange={(e: any) => {
                            handleSelectStore(e);
                        }}
                        value={selectedStore}
                    />
                </div>

                <div
                    className="row justify-content-center mt-4"
                    ref={searchTabContainer}
                >
                    <div
                        className="col-md-5 mx-auto position-relative p-0"
                        onClick={() => setPreviewTab(!previewTab)}
                    >
                        <Search
                            className="w-100"
                            searchCallBack={(e: string) => {
                                handleSearch(e);
                            }}
                            id="search"
                            placeholder={"Search Barcode"}
                            type="search"
                        />
                        {(previewTab && allProductList && allProductList?.length > 0) && (
                            <SearchTab
                                data={allProductList}
                                searchCallBack={(e: string) => handleSearchSelect(e)}
                                productData={productDataTable}
                            />
                        )}
                    </div>

                    {productDataTable && productDataTable?.length > 0 && <div>
                        <Table
                            AdditionalClassName="add-invertory-table data-table-container"
                            columns={columns}
                            data={productDataTable?.map((item, index) => {
                                return ({
                                    ...item,
                                    action:
                                        <div className='d-flex align-items-center text-danger' >
                                            <IoIosCloseCircleOutline className='fs-24' onClick={() => handleRemoveProduct(index, item?.id)} />
                                        </div>
                                })
                            })}
                        />
                    </div>}
                    <div className='text-center mt-5 '>
                        <Button isDisabled={(productDataTable?.length === 0 || !selectedStore)} className="w-25" onClick={() => handleSubmit()}>Submit</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
