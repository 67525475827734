import React, { useContext, useEffect, useRef, useState } from "react";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { HeaderHeading } from "../../../../Context";
import { getDropdown } from "../../../../constants/utils";
import service from "../../../../api/services";
import { useToasts } from "react-toast-notifications";
import { AllProductListProps, SelectedStoreProps } from "./interface";

const { BulkInternalTransfer } = organisms


const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const [storeList, setStoreList] = useState<any[]>([])
    const [selectedStore, setSelectedStore] = useState<SelectedStoreProps>()
    const [allProductList, setAllProductList] = useState<AllProductListProps[]>([])
    const [productDataTable, setProductDataTable] = useState<AllProductListProps[]>([])
    const [previewTab, setPreviewTab] = useState<boolean>(false);
    const searchTabContainer = useRef<HTMLDivElement>(null);

    const { addToast } = useToasts();

    const getStoreList = async () => {
        try {
            const response = await service.internalTransfer.storeList();
            if (response.status === 200 || response.status === 201) {
                setStoreList(getDropdown(response?.data?.data, "store_name"));
            }
        } catch (error: any) {
            console.error("error", error);
            addToast(error?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    const handleSearch = async (value: string) => {
        try {
            const response = await service.internalTransfer.barcodeSearch({ search: value });
            if (response.status === 200 || response.status === 201) {
                response?.data?.data &&  setAllProductList([{
                    id: response?.data?.data?.id,
                    barcode: response?.data?.data?.barcode,
                    ...response?.data?.data?.product
                }]);
                setPreviewTab(true)
            }
        } catch (error: any) {
            console.error("error", error);
            addToast(error?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }

    }

    const handleSubmit = async () => {
        const ids = productDataTable?.map((item: any) => item.id)
        const payload = {
            destination_store_id: selectedStore?.value,
            inventory_items: ids
        }
        try {
            const response = await service.internalTransfer.bulkInternalTransfer(payload);
            if (response.status === 200 || response.status === 201) {
                setAllProductList([{
                    id: response?.data?.data?.id,
                    barcode: response?.data?.data?.barcode,
                    ...response?.data?.data?.product
                }]);
                setPreviewTab(true)
            }
        } catch (error: any) {
            console.error("error", error);
            addToast(error?.response?.data?.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }



    const handleRemoveProduct = (id: string) => {
        setProductDataTable(prevData => prevData.filter(item => item?.id !== id));
    }

    const handleSearchSelect = (value: AllProductListProps) => {
        if (!productDataTable.some(item => item?.id === value.id)) {
            setProductDataTable([value, ...productDataTable]);
        }
    };

    const handleClickOutside = (event: any) => {
        if (searchTabContainer.current && !searchTabContainer.current.contains(event.target)) {
            setPreviewTab(false);
        }
    };



    useEffect(() => {
        setHeader("Transfer Requests - Bulk Internal Transfer")
        getStoreList()
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Wrapper>
            <BulkInternalTransfer
                handleSelectStore={(value: any) => setSelectedStore(value)}
                selectedStore={selectedStore}
                storeList={storeList}
                searchTabContainer={searchTabContainer}
                handleSearch={(e: string) => handleSearch(e)}
                setPreviewTab={setPreviewTab}
                previewTab={previewTab}
                allProductList={allProductList}
                handleSearchSelect={(item: AllProductListProps) => handleSearchSelect(item)}
                productDataTable={productDataTable}
                handleRemoveProduct={(index: number, id: string) => handleRemoveProduct(id)}
                handleSubmit={() => handleSubmit()}
            />
        </Wrapper>
    )
}

export default Index