import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import moment from "moment";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import { convertJsonToExcel, numberWithCommas } from "../../../../constants/utils";
import { MoveInColumnsProps, MyComponentProps, ProductListTypeProps } from "./interface";

const { ContactLensContainer } = molecules
const { Button, Pagination, Search, Table, Input } = atoms

const Index: React.FC<MyComponentProps> = ({ 
    transferRequestedList, 
    setSearch, 
    currentPage, 
    setCurrentPage, 
    setLimitPerPage, 
    limit, 
    isContactLensProduct, 
    setIsContactLensProduct,
    setCreatedFrom,
    setCreatedTo,
 }) => {

    const columns = [
        { name: 'Product ID', selector: (row: ProductListTypeProps) => row?.product?.product_id_display, minWidth: '10vw' },
        { name: 'Brand', selector: (row: ProductListTypeProps) => row?.product?.brand?.brand_name || '-', },
        { name: 'Cover Photo', selector: (row: ProductListTypeProps) => <img src={row?.product?.images[0] && row?.product?.images[0]} className='w-50 rounded object-contain' /> },
        { name: 'Model No', selector: (row: ProductListTypeProps) => row?.product?.modal_number || '-', },
        { name: 'Brand Color Code', selector: (row: ProductListTypeProps) => row?.product?.brand_color_code || '-', },
        { name: 'With Case', selector: (row: ProductListTypeProps) => row?.product?.product_with_case ? "Yes" : "No", },
        { name: 'Date', selector: (row: ProductListTypeProps) => row?.createdAt && moment(row?.createdAt).format('DD/MM/YYYY') || '-', },
        { name: 'Product Type', selector: (row: ProductListTypeProps) => row?.product?.product_type?.name, },
        { name: 'Glass Size/Power', selector: (row: ProductListTypeProps) => row?.product?.glass_size || '-', },
        { name: 'SRP', selector: (row: ProductListTypeProps) => `₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: ProductListTypeProps) => `₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Pickup from', selector: (row: ProductListTypeProps) => row?.pickup_store?.store_location || '-', },
        
    ]
    const columnsForContactLens = [
        { name: 'Product ID', selector: (row: ProductListTypeProps) => row?.product?.product_id_display, minWidth: '10vw' },
        { name: 'Brand', selector: (row: ProductListTypeProps) => row?.product?.brand?.brand_name || '-', },
        { name: 'Cover Photo', selector: (row: ProductListTypeProps) => <img src={row?.product?.images[0] && row?.product?.images[0]} className='w-50 rounded object-contain' /> },
        { name: 'Date', selector: (row: ProductListTypeProps) => row?.createdAt && moment(row?.createdAt).format('DD/MM/YYYY') || '-', },
        { name: 'Product Type', selector: (row: ProductListTypeProps) => row?.product?.product_type?.name, },
        { name: 'SRP', selector: (row: ProductListTypeProps) => `₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: ProductListTypeProps) => `₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Requested Store', selector: (row: ProductListTypeProps) => row?.pickup_store?.store_location || '-', },
    ]

    const handleDownloadExcel = () => {
        if (transferRequestedList?.data?.length > 0) {
            const excelData = transferRequestedList?.data?.map((item: ProductListTypeProps) => {
                const rowData: { [key: string]: string | number | "-" } = {};
                columns.forEach((column: MoveInColumnsProps) => {
                    if (column.name != "Cover Photo" && column?.selector) {
                        const value = column.selector(item);
                        rowData[column.name] = typeof value === 'string' || typeof value === 'number' ? value : '-';
                    }
                });
                return rowData;
            });

            convertJsonToExcel(excelData, "Inventory_excel")
        }
    }


    return (
        <>
            <div className="d-flex mb-3">
                <ContactLensContainer
                    value={isContactLensProduct}
                    onStatusChange={(value: boolean) => setIsContactLensProduct(value)}
                />
            </div>
            <div className="row">
                <div className="col-md-4"><Search className="w-md-50 w-100" searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className='col-12 col-md-8 gap-2 d-lg-flex justify-content-lg-end px-lg-0'>
                    <div className='row ps-lg-3 justify-content-end'>
                        <div className="col-6 d-md-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label fs-16 fw-600 secondary-color'>From</label>
                            <div className='w-100'>
                                <Input
                                    type={'Date'}
                                    className="input-height change-min-height"
                                    placeholder={"Enter End Date"}
                                    onChange={(e: any) => {
                                        setCreatedFrom(e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="col-6 d-md-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label fs-16 fw-600 secondary-color'>To</label>
                            <div className='w-100'>
                                <Input
                                    type={'Date'}
                                    className="input-height change-min-height"
                                    placeholder={"Enter Start Date"}
                                    onChange={(e: any) => {
                                        setCreatedTo(e.target.value)
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-lg-center justify-content-end'>
                        <Button onClick={() => handleDownloadExcel()}>Download</Button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    columns={isContactLensProduct ? columnsForContactLens : columns}
                    AdditionalClassName="table-height-50"
                    data={transferRequestedList?.data?.map((item: any) => {
                        return ({
                            ...item,
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={transferRequestedList?.count}
                    currentPage={currentPage}
                    totalPages={transferRequestedList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index