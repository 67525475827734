import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { internalTransfer } = Dictionary

const InternalTransfer = {
    internalTransfer: async (payload:{}) => {
        return axios.post(BASE_URL + internalTransfer.bulkInternalTransfer(),payload)
    },
    storeList: async () => {
        return axios.get(BASE_URL + internalTransfer.storeList() )
    },
    barcodeSearch:async (search:{}) =>{
        return axios.get(BASE_URL + internalTransfer.barcodeSearch(),{ params: search } )
    },
    bulkInternalTransfer:async (payload:{})=>{
        return axios.post(BASE_URL + internalTransfer.bulkInternalTransfer(),payload)
    }

}
export default InternalTransfer