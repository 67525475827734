import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import service from "../../../api/services";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import molecules from "../../molecules";
import atoms from "../../atoms";
import { LensDetailsProps, LensDetailsStateProps, LensDetailsValuesProps } from "./interface";

const { Search, Button, Table, Input } = atoms;
const { ConfirmationModal, CreateModal } = molecules;

const Index = ({
    productTypeListing,
    getLensDetailList,
}: LensDetailsProps) => {
    const { addToast } = useToasts();
    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false);
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [lensDetailsValues, setLensDetailsValues] = useState<LensDetailsValuesProps>({
        heading: "",
        amount: NaN,
        description: "",
    });
    const [selectedId, setSelectedId] = useState<string>("");
    const [errors, setErrors] = useState<any>({});
    const columns = [
        {
            name: "Heading",
            selector: (row: LensDetailsStateProps) => row.lens_data?.heading,
            minWidth: "20%",
        },
        {
            name: "Amount",
            selector: (row: LensDetailsStateProps) => row.lens_data?.amount,
            sortable: true,
            minWidth: "20%",
        },
        {
            name: "Description",
            selector: (row: LensDetailsStateProps) => row.lens_data?.description,
            minWidth: "10%",
        },
        { name: "Action", selector: (row: any) => row.action, minWidth: "30%" },
    ];
    const handleSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            const payload = {
                heading: lensDetailsValues.heading,
                amount: lensDetailsValues.amount,
                description: lensDetailsValues.description,
            };
            try {
                if (isEdit) {
                    // for update product type values
                    const response = await service.lensDetails.update(
                        selectedId,
                        payload
                    );
                    if (response.status === 200) {
                        setCreateModal(false);
                        getLensDetailList()
                        addToast(response?.data?.message, {
                            appearance: "success",
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                } else {
                    // for Create lens details values
                    const response = await service.lensDetails.create(
                        payload
                    );
                    if (response.status === 201) {
                        getLensDetailList()
                        setCreateModal(false);
                        addToast(response?.data?.message, {
                            appearance: "success",
                            autoDismiss: true,
                            autoDismissTimeout: 3000,
                        });
                    }
                }
            } catch (error: any) {
                console.log("error", error);
                addToast(error.response.data.message, {
                    appearance: "error",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
            setLensDetailsValues({
                heading: "",
                amount: NaN,
                description: "",
            });
            setIsEdit(false);
        }
    };
    const _handleDelete = (id: string) => {
        setSelectedId(id);
        setHandleDeleteModal(true);
    };
    const handleDeleteType = async () => {
        try {
            const response = await service.lensDetails.delete(
                selectedId
            );
            if (response.status === 200) {
                setHandleDeleteModal(false);
                getLensDetailList()
                addToast(response?.data?.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log("error", error);
            setHandleDeleteModal(false);
            addToast(error.response.data.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    const _handleEdit = async (id: string) => {
        setSelectedId(id);
        handleProductTypeValues(id);
    };
    const handleProductTypeValues = async (id: string) => {
        setIsEdit(true);
        try {
            const response = await service.lensDetails.getById(id);
            let resData = response.data.data;
            if (response.status === 200) {
                setLensDetailsValues({
                    heading: resData?.lens_data?.heading,
                    amount: resData?.lens_data?.amount,
                    description: resData?.lens_data?.description,
                });
                setCreateModal(true);
                getLensDetailList()
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error.response.data.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };
    const _handleClose = () => {
        setCreateModal(!createModal);
        setLensDetailsValues({
            heading: "",
            amount: NaN,
            description: "",
        });
        setIsEdit(false);
    };
    const handleChange = (name: string, value: string) => {
        setLensDetailsValues({ ...lensDetailsValues, [name]: value });
        setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
    };
    const validationRules: any = {
        heading: {
            required: true,
        },
        amount: {
            required: true,
        },
        description: {
            required: true,
        },
    };
    const validate = () => {
        let newErrors: any = {};
        for (const field in validationRules) {
            if (validationRules.hasOwnProperty(field)) {
                const fieldValue = (lensDetailsValues as Record<string, any>)[field];
                if (validationRules[field]?.required && !fieldValue) {
                    newErrors[field] = "This field is required.";
                }
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    return (
        <div>
            <div>
                <div>
                    <div className="text-end">
                        <Button
                            name={"Add Lens Detail"}
                            onClick={() => setCreateModal(!createModal)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    data={productTypeListing?.data?.map((item: any) => {
                        return {
                            ...item,
                            action: (
                                <div style={{ overflow: "unset", textOverflow: "unset" }}>
                                    <Button
                                        className="table-edit-button fs-14 fw-400"
                                        onClick={() => _handleEdit(item.id)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <FiEdit />
                                            <span className="ms-2 mb-0">Edit</span>
                                        </div>
                                    </Button>
                                    <Button
                                        className="table-delete-button fs-14 fw-400 ms-3"
                                        onClick={() => _handleDelete(item.id)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <RiDeleteBin6Line />
                                            <span className="ms-2 mb-0">Delete</span>
                                        </div>
                                    </Button>
                                </div>
                            ),
                        };
                    })}
                />
            </div>
            {handleDeleteModal && (
                <ConfirmationModal
                    show={handleDeleteModal}
                    handleConfirmItem={() => handleDeleteType()}
                    onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                    message={"delete this Lens Detail"}
                />
            )}

            {createModal && (
                <CreateModal
                    show={createModal}
                    header={`${isEdit ? "Update" : "Add"} Lens Details`}
                    handleSubmit={handleSubmit}
                    submitBtnText={`${isEdit ? "Update" : "Add"}`}
                    onHide={() => _handleClose()}
                >
                    <div>
                        <form>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Heading"
                                        onChange={(e: any) => handleChange("heading", e.target.value)}
                                        defaultValue={lensDetailsValues.heading}
                                        label="Heading"
                                        errors={errors.heading}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter Lens Amount"
                                        onChange={(e: any) =>
                                            handleChange("amount", e.target.value)
                                        }
                                        defaultValue={lensDetailsValues.amount}
                                        label="Lens Amount"
                                        errors={errors.amount}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mt-25">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Description"
                                        onChange={(e: any) => handleChange("description", e.target.value)}
                                        defaultValue={lensDetailsValues.description}
                                        label="Description"
                                        errors={errors.description}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </CreateModal>
            )}
        </div>
    );
};

export default Index;
