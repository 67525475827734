import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { lensDetails } = Dictionary

const LensDetails = {
    create: async (payload:{}) => {
        return axios.post(BASE_URL + lensDetails.create(), payload);
    },
    list: async () => {
        return axios.get(BASE_URL + lensDetails.list());
    },
    delete: async (id:string) => {
        return axios.delete(BASE_URL + lensDetails.delete(id));
    },
    update: async (id:string, payload:{}) => {
        return axios.put(BASE_URL + lensDetails.update(id), payload);
    },
    getById: async (id:string) => {
        return axios.get(BASE_URL + lensDetails.getById(id));
    },

}
export default LensDetails