export const AllStaffPermissions = {

    Dashboard_Tab: '258D5',
    Dashboard_Pending_Order :'A4G7H',
    Dashboard_Inventory_Audit :'2C8D9',
    Dashboard_My_Performance :'Z5P3R',

    Sales_Tab: 'B65B9',
    // sub tab permission code
    Pending_Order_Tab: 'D8C5C',
    Sales_History_Tab: 'FL9B4',
    Cancelled_Order_Tab: '08DD6',
    Sales_Return_Tab: 'A9C77',
    Sales_Return_History_Tab: 'L1HP5',


    Transfer_Tab: 'DAF3C',

    Product_Tab: 'EF1E6',

    Warranty_Tab: '2T6AE',
    // sub tab permission code
    New_Claim_Tab: '9AT8A',
    Claim_In_Process_Tab: '2BC9B',
    Claim_History_Tab: '9FEF6',


    Prescription_Tab: '4F9L3',

    Refund_Tab: '0CA8F',
    // sub tab permission code
    Payment_Voucher_Tab: 'DB2P8',
    Payment_Voucher_History_Tab: 'EF5D2',
    

    Sales_Cart_Tab: 'BC7Z3',

    Inventory_Audit_Tab: '7BC9A',

}