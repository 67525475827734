import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import atoms from "../../atoms";
import { SKUProps, SKUStateProps } from "./interface";

const { Button, Table } = atoms;

const Index = ({
    skuListing,
    setCreateModal,
    createModal,
    _handleEdit,
    _handleDelete,
    setIsEdit
}: SKUProps) => {

    const columns = [
        {
            name: "SKU Number",
            selector: (row: SKUStateProps) => row.sku_number,
            minWidth: "20%",
        },
        { name: "Action", selector: (row: any) => row.action, minWidth: "30%" },
    ];


    return (
        <div>
            <div>
                <div>
                    <div className="text-end">
                        <Button
                            name={"Add Lens Detail"}
                            onClick={() => setCreateModal(!createModal)}
                        />
                    </div>
                </div>
            </div>
         {skuListing && <div>
                <Table
                    columns={columns}
                    data={skuListing?.map((item: any) => {
                        return {
                            ...item,
                            action: (
                                <div style={{ overflow: "unset", textOverflow: "unset" }}>
                                    <Button
                                        className="table-edit-button fs-14 fw-400"
                                        onClick={() => _handleEdit(item.id, item?.sku_number)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <FiEdit />
                                            <span className="ms-2 mb-0">Edit</span>
                                        </div>
                                    </Button>
                                    <Button
                                        className="table-delete-button fs-14 fw-400 ms-3"
                                        onClick={() => _handleDelete(item.id,item?.sku_number)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <RiDeleteBin6Line />
                                            <span className="ms-2 mb-0">Delete</span>
                                        </div>
                                    </Button>
                                </div>
                            ),
                        };
                    })}
                />
            </div>}
        </div>
    );
};

export default Index;
