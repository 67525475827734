import React from 'react'
import Images from '../../../assets/Images'
import { Tooltip } from 'react-tooltip'
import constant from '../../../constants/constant';
import { findConstantLabel, numberWithCommas, renderOptions } from '../../../constants/utils'
import atoms from '../../atoms'
import service from '../../../api/services';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';

const { Button } = atoms
const { Copy } = Images
const Index = ({ productDetails, _handleProductId, title }: any) => {
    const { addToast } = useToasts();
    const navigate = useNavigate()
    let filterPath = localStorage.getItem("product_filter") || constant.PRODUCT_FILTER_TYPE.DEFAULT;
    const productData = productDetails
    const colors = productDetails?.extra_details?.variants;
    const periodUse = productDetails?.extra_details?.period_use
    const packOfQuantity = productDetails?.extra_details?.pack_of_quantity
    const glassSize = productDetails?.extra_details?.glass_sizes
    // const coloredDivs = colors && colors.map((color: any, index: number) => {
    //     if (color.specx_colors.length === 2) {
    //         // Handle the case where there are two colors
    //         const [color1, color2] = color.specx_colors;
    //         return (
    //             <div className={color?.brand_color_code == productDetails?.brand_color_code ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
    //                 onClick={() => { _handleProductId(color.product_id) }}>
    //                 <div key={`color${index}`} className="diagonal-color-div cursor-pointer"
    //                     data-tooltip-id={`color${index}`}
    //                     data-tooltip-content={`${color.brand_color_name}, ${color.brand_color_code}`}
    //                 >
    //                     <div
    //                         className="diagonal-color-div cursor-pointer rounded-circle"
    //                         style={{ backgroundColor: color1.color_code }}
    //                     ></div>
    //                     <Tooltip id={`color${index}`} />
    //                 </div>
    //             </div>
    //         );
    //     } else if (color.specx_colors.length === 1) {
    //         // Handle the case where there is only one color
    //         const singleColor = color.specx_colors[0];
    //         return (
    //             <div className={
    //                 (color?.brand_color_code == productDetails?.brand_color_code) ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
    //                 onClick={() => { _handleProductId(color.product_id) }}>
    //                 <div key={`color${index}`}
    //                     style={{ backgroundColor: singleColor.color_code }}
    //                     className="color-div cursor-pointer"
    //                     data-tooltip-id={`color${index}`}
    //                     // data-tooltip-content={color.brand_color_name}
    //                     data-tooltip-content={`${color.brand_color_name}, ${color.brand_color_code}`}>
    //                     <Tooltip id={`color${index}`} />
    //                 </div>
    //             </div>

    //         );
    //     } else {
    //         return null; // Handle other cases or add error handling as needed
    //     }
    // });

    const coloredDivs = colors && colors.map((color: any, index: number) => {
        if (color?.specx_colors && color?.specx_colors?.length > 0) {
            // Handle the case where there is only one color
            const singleColor = color.specx_colors[0];
            return (
                <div className={
                    (color?.brand_color_code == productDetails?.brand_color_code) ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
                    onClick={() => { _handleProductId(color.product_id) }}>
                    <div key={`color${index}`}
                        style={{ backgroundColor: singleColor?.color_code }}
                        className="color-div cursor-pointer"
                        data-tooltip-id={`color${index}`}
                        // data-tooltip-content={color.brand_color_name}
                        data-tooltip-content={`${color.brand_color_name}, ${color.brand_color_code}`}>
                        <Tooltip id={`color${index}`} />
                    </div>
                </div>

            );
        } else {
            return null; // Handle other cases or add error handling as needed
        }
    });
    const coloredContactLensDivs = colors && colors?.map((color: any, index: number) => {
        if (productData?.is_contact_lens) {
            const singleColor = color
            return (
                <div className={
                    (color?.color_code === productData?.color?.color_code) ? 'p-1 active-border-color rounded-circle' : 'p-1 border border-white rounded-circle'}
                    onClick={() => { _handleProductId(color.product_id) }}>
                    <div key={`color${index}`}
                        style={{ backgroundColor: singleColor?.color_code }}
                        className="color-div cursor-pointer"
                        data-tooltip-id={`color${index}`}
                        data-tooltip-content={`${color.color_name}, ${color.color_code}`}>
                        <Tooltip id={`color${index}`} />
                    </div>
                </div>

            );
        } else {
            return null;
        }
    });

    const manageMultiplePeriod = renderOptions(
        periodUse,
        productDetails?.period_use,
        "period_use",
        _handleProductId,true
    );

    const manageMultipleQuantity = renderOptions(
        packOfQuantity,
        productDetails?.pack_of_quantity,
        "pack_of_quantity",
        _handleProductId,false
    );
    const manageMultipleGlassSize = renderOptions(
        glassSize,
        productDetails?.glass_size,
        "glass_size",
        _handleProductId,false
    );

    const productWarranty = constant.PRODUCT_WARRANTY.find(warranty => warranty.value === productData?.warranty);

    const _handleAddToCart = async (productID: string) => {
        try {
            const payload = {
                product_id: productID
            }
            const response = await service.product.addToCart(payload)
            if (response.status === 200) {
                // navigate('/product/list')
                navigate(`/product/list?type=${filterPath}`)
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            console.log(error);
        }
    }
    return (
        <>
            <div className="row mt-md-4 mt-2 ">
                <div className="col-lg-7 mt-4 mt-md-3 order-2 order-md-1">
                    <h2 className='mb-0 fs-md-20 fs-16 fw-600'>{productData?.brand?.brand_name}</h2>
                    <h2 className='mb-0 fs-md-24 fs-16 fw-700 mt-2'>{title}</h2>
                    <div className='d-flex gap-3'>
                        <h2 className='mb-0 fs-md-24 fs-16  fw-500 mt-md-3 mt-2'>₹{numberWithCommas(parseFloat(productData?.product_srp || 0))} (SRP)</h2>
                        <h2 className='mb-0 fs-md-24 fs-16 fw-500 mt-md-3 mt-2'>₹{numberWithCommas(parseFloat(productData?.brand_cost_amount || 0))}(L.Cost)</h2>
                    </div>

                </div>

                <div className="d-none d-md-block col-lg-5 mt-4 mt-md-3 order-1 order-md-2">
                    <div className='d-md-flex align-items-center justify-content-start justify-content-lg-end gap-2'>
                        <h2 className='mb-0 fs-16 fw-600'>Product ID :-</h2>
                        <div className='id-input-design w-lg-100 w-md-50 d-flex mt-md-0 mt-2 justify-content-between align-items-center gap-2'>
                            <h2 className='mb-0 fs-16 fw-400'>{productData?.product_id_display}</h2>
                            <img src={Copy} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            {productData?.is_contact_lens ? <div className='d-flex gap-3 mt-sm-3 mt-2'>
                <h2 className='my-auto fs-md-18 fs-12 fw-600' style={{ minWidth: "7vw" }}>Period use :</h2>
                <div className='d-flex gap-2' style={{ flexWrap: "wrap" }}>
                {manageMultiplePeriod}
                </div>

            </div > : <div className='d-flex gap-md-3 gap-2 mt-3'>
                <h2 className='mb-0 fs-md-18 fs-12 fw-600 mt-1' style={{ minWidth: "20px" }}>Product Color :</h2>
                <div className='d-flex gap-2' style={{ flexWrap: "wrap" }}>
                    {coloredDivs}
                </div>

            </div >}
            {productData?.is_contact_lens ?
                <div className='d-flex gap-3 mt-sm-3 mt-2'>
                    <h2 className='my-auto fs-md-18 fs-12 fw-600' style={{ minWidth: "7vw" }}>Pack of :</h2>
                    <div className='d-flex gap-2' style={{ flexWrap: "wrap" }}>
                    {manageMultipleQuantity}
                    </div>

                </div>
                : <div className='d-flex align-items-center gap-md-3 gap-2 mt-md-4 mt-2'>
                    <h2 className='fs-md-18 fs-12 fw-700 my-auto'>Glass Size/Power :</h2>
                    <div className='d-flex gap-2'>
                        {/* {productDetails?.extra_details?.glass_sizes?.map((item: any, index: number) => {
                            return (
                                <p className='glass-div cursor-pointer fw-700 fs-md-18 fs-12 my-auto' key={`glass${index}`}
                                    style={{
                                        borderColor: (productDetails?.glass_size == item?.glass_size) ? "#05b7e4" : "black",
                                        backgroundColor: (productDetails?.glass_size == item?.glass_size) ? "#05b7e4" : "#fff",
                                        color: (productDetails?.glass_size == item?.glass_size) ? "#fff" : "black"

                                    }}
                                    onClick={() => _handleProductId(item.product_id)}
                                >{item?.glass_size}</p>
                            )
                        })} */}
                        {manageMultipleGlassSize}
                    </div>
                </div>}

            {productData?.is_contact_lens ? <div className='d-flex gap-3 mt-sm-3 mt-2'>
                <h2 className='my-auto fs-md-18 fs-12 fw-600' style={{ minWidth: "7vw" }}>Color :</h2>
                <div className='d-flex gap-2' style={{ flexWrap: "wrap" }}>
                {coloredContactLensDivs}
                </div>

            </div> :
                productData?.isPolarised !== constant.POLARIZED[2].value && <div className='d-flex align-items-center gap-3 mt-md-4 mt-2'>
                    <h2 className='mb-0 fs-md-18 fs-12 fw-700'>Polarized</h2>
                    {productDetails?.extra_details?.polarized?.map((item: any, index: number) => {
                        return (
                            <>
                                {item?.isPolarised == constant.POLARIZED[0].value && <div className='d-flex gap-2'>
                                    <label>
                                        <input type="radio" name="polarized1" value={1} onChange={() => _handleProductId(item?.isPolarised == constant.POLARIZED[0].value && item.product_id)}
                                            checked={productData?.isPolarised === constant.POLARIZED[0].value} />
                                        <span
                                            className={!productDetails?.extra_details?.polarized[0] ? 'fs-md-18 fs-12 fw-500 text-secondary' : 'fs-md-18 fs-12 fw-500'}
                                        > Yes</span>
                                    </label>
                                </div>}
                                {item?.isPolarised == constant.POLARIZED[1].value && <div className='d-flex gap-2'>
                                    <label>
                                        <input type="radio" name="polarized1" value={2}
                                            onChange={() => _handleProductId(item?.isPolarised == constant.POLARIZED[1].value && item.product_id)}
                                            checked={productData?.isPolarised === constant.POLARIZED[1].value}
                                        />
                                        <span
                                            className={productDetails?.extra_details?.polarized[0] == 2 ?
                                                'fs-md-18 fs-12 fw-500 text-secondary' : 'fs-md-18 fs-12 fw-500'}
                                        > No</span>
                                    </label>
                                </div>}
                            </>
                        )
                    })}
                </div>}

            {!productData?.is_contact_lens  && <div className="row align-items-center">
                <div className='col-md-6 row pe-md-0'>

                    <div className="col-6 mt-md-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-md-1 gap-lg-2 gap-2 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Temple Size :</h2>
                            <span className='fs-md-18 fs-12 fw-400'>{productData?.temple_size}</span>
                        </div>
                    </div>
                    <div className="col-6 mt-md-3 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-md-1 gap-lg-2 gap-2 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Lens Color  :</h2>
                            {productData?.lens_colors?.map((item: any, index: number) => {
                                return (
                                    <div key={`color${index}`}
                                        className="color-div "
                                        style={{ backgroundColor: item.color_code, borderRadius: '0px' }}>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className='col-md-6 pe-md-0 row'>
                    <div className="col-6 col-md-5 mt-md-3 mt-lg-0 mt-2 px-md-0">
                        <div className="d-flex flex-wrap align-items-center gap-md-3 gap-2 gap-md-1 gap-lg-2 ">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Bridge Size :</h2>
                            <span className='fs-md-18 fs-12 fw-400'>{productData?.bridge_size}</span>
                        </div>
                    </div>

                    <div className="col-6 col-md-7 mt-md-3 mt-2 px-md-0">
                        <div className="d-flex flex-wrap align-items-center gap-md-0 gap-lg-1">
                            <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Warrantee :</h2>
                            <span className='fs-md-18 fs-12 fw-400'>
                                {productWarranty ? productWarranty.label : "Na"}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
}
           {!productData?.is_contact_lens  &&  <div className="row align-items-center">
                {/* <div className="col-md-3 col-6 mt-sm-3 pr-0"> */}
                <div className="col-6 mt-md-3 mt-2 row">
                    <div className="d-flex flex-wrap align-items-center gap-md-3 gap-2 ">
                        <h2 className='mb-0 fs-md-18 fs-12 fw-600'>Product with case :</h2>
                        <span className='fs-md-18 fs-12 fw-400'>{productData?.product_with_case ? 'Yes' : 'No'}</span>
                    </div>
                </div>
                <div className="col-md-3 col-6 mt-md-3 mt-2">
                    <div className="d-flex gap-md-3 gap-1 flex-wrap align-items-center">
                        <h2 className='mb-0 fs-md-18 fs-12 fw-600 gap-md-3 gap-1'>Features :</h2>
                        {productData?.features && productData?.features?.map((item: any, index: number) => {
                            return (
                                <span className='fs-md-18 fs-12 fw-400'
                                >{`${findConstantLabel(constant.PRODUCT_FEATURES, item)}${index < (productData?.features?.length - 1) ? "," : ""}`}</span>
                            )
                        })}
                    </div>
                </div>
                <div className='d-md-none col-3'></div>
                {/* </div> */}
            </div>}

            <div className="row my-md-4 mt-3">
                <div className="col">
                    <div className="d-block align-items-center gap-3 ">
                        <h2 className='mb-0 fs-md-20 fs-14 fw-600 mb-md-3 mb-1'>Product Description :</h2>
                        <span className='fs-md-18 fs-12 fw-500 '>{productData?.description}</span>
                    </div>

                </div>
            </div>
            <div className="row my-4">
                <div className="col">
                    <Button name={'Add To Cart'}
                        onClick={() => _handleAddToCart(productData?.id)}
                        className={`w-100 background-dark-gray fw-700 fs-md-18 fs-12`} />
                </div>
            </div>
        </>
    )
}

export default Index