import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from "react-toast-notifications";
import service from "../../../../api/services";
import { HeaderHeading } from "../../../../Context";
import {handleReportDownload} from '../../../../constants/utils'


const { CollectionReport } = organisms

const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [reportDate, setReportDate] = useState({
        created_from: Date,
        created_to: Date,
        report_format: NaN
    })

    useEffect(() => {
        setHeader("Reports - Collection Report")
    }, [])

    const handleChange = (name: string, value: Date) => {
        setReportDate({ ...reportDate, [name]: value })
    }

    const handleSubmit = async (type: string) => {

        try {
            const response = await service.reportService?.collection({ created_from: reportDate?.created_from, created_to: reportDate?.created_to })
            if(response?.status === 200){
                handleReportDownload(response?.data?.data, "collection-report", reportDate?.report_format, "Collection Report");
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    return (
        <Wrapper>
            <CollectionReport
                reportDate={reportDate}
                handleChange={(name: string, value: Date) => handleChange(name, value)}
                handleSubmit={(type: string) => handleSubmit(type)} />
        </Wrapper>
    )
}

export default Index