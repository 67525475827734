import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import organisms from "../../../components/organisms";
import { HeaderHeading } from "../../../Context";
import molecules from "../../../components/molecules";
import atoms from "../../../components/atoms";
import { useToasts } from "react-toast-notifications";
import { SkuListingProps } from "./interface";
import service from "../../../api/services";

const { SKU } = organisms
const { Input } = atoms;
const { ConfirmationModal, CreateModal } = molecules;

const Index = () => {
    const { setHeader }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();

    const [createModal, setCreateModal] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [skuListing, setSkuListing] = useState<SkuListingProps[] | null>(null)
    const [skuNumber, setSkuNumber] = useState<string>('')
    const [selectedId, setSelectedId] = useState<string>("");

    const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false);

    const getSKUList = async () => {
        try {
            const response = await service.sku.list();
            setSkuListing(response?.data?.data);
        } catch (error) { }
    };

    const handleSubmit = async () => {
        const payload = {
            sku_number: skuNumber
        };
        try {
            if (isEdit) {
                // for update product type values
                const response = await service.sku.update(
                    selectedId,
                    payload
                );
                if (response.status === 200) {
                    setCreateModal(false);
                    getSKUList();
                    addToast(response?.data?.message, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            } else {
                // for Create lens details values
                const response = await service.sku.create(
                    payload
                );
                if (response.status === 201) {
                    getSKUList()
                    setCreateModal(false);
                    addToast(response?.data?.message, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            }
        } catch (error: any) {
            console.log("error", error);
            addToast(error.response.data.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        setIsEdit(false)
        setSelectedId('');
        setSkuNumber('')
        setCreateModal(!createModal)
    };

    const _handleDelete = (id: string) => {
        setSelectedId(id);
        setHandleDeleteModal(true);
    };
    const handleDeleteType = async () => {
        try {
            const response = await service.sku.delete(
                selectedId
            );
            if (response.status === 200) {
                setHandleDeleteModal(false);
                getSKUList()
                addToast(response?.data?.message, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setSkuNumber('');
                setSelectedId('')
            }
        } catch (error: any) {
            console.log("error", error);
            setHandleDeleteModal(false);
            addToast(error.response.data.message, {
                appearance: "error",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    };

    const _handleEdit = async (id: string, value: string) => {
        setIsEdit(true)
        setSelectedId(id);
        setSkuNumber(value)
        setCreateModal(!createModal)
    };

    const _handleClose = () => {
        setCreateModal(!createModal);
        setSkuNumber('')
        setIsEdit(false);
    };

    useEffect(() => {
        setHeader("SKU");
        getSKUList()
    }, []);
    return (
        <>
            <Wrapper>
                <SKU
                    skuListing={skuListing}
                    setCreateModal={(value: boolean) => setCreateModal(value)}
                    createModal={createModal}
                    _handleEdit={(id: string, value: string) => _handleEdit(id, value)}
                    _handleDelete={(id: string, value: string) => _handleDelete(id)}
                    setIsEdit={setIsEdit}
                />
            </Wrapper>
            {handleDeleteModal && (
                <ConfirmationModal
                    show={handleDeleteModal}
                    handleConfirmItem={() => handleDeleteType()}
                    onHide={() => setHandleDeleteModal(!handleDeleteModal)}
                    message={"delete this Lens Detail"}
                />
            )}

            {createModal && (
                <CreateModal
                    show={createModal}
                    header={`${isEdit ? "Update" : "Add"} SKU`}
                    handleSubmit={handleSubmit}
                    submitBtnText={`${isEdit ? "Update" : "Add"}`}
                    onHide={() => _handleClose()}
                >
                    <div>
                        <form>
                            <div className="mb-3">
                                <div>
                                    <Input
                                        type="tex"
                                        value={skuNumber}
                                        className="form-control"
                                        placeholder="Enter SKU Number"
                                        onChange={(e: any) => setSkuNumber(e.target.value)
                                        }
                                        label="SKU Number"
                                        errors={""}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </CreateModal>
            )}
        </>
    )
}

export default Index