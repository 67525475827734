import React from "react";
import {
  DataProps,
  HandleVerificationStatusProps,
  IndexProps,
} from "./interface";
import atoms from "../../atoms";
import { FiEdit } from "react-icons/fi";
import constant from "../../../constants/constant";

const { Table, Button } = atoms;

const Index = ({
  setShowModal,
  setModalType,
  settingData,
}: IndexProps) => {
  const columns = [
    {
      name: "S.No.",
      selector: (row: { index_number: JSX.Element }) => row.index_number,
      maxWidth: "20px",
    },
    { name: "Name", selector: (row: DataProps) => row?.name, sortable: true },
    {
      name: "Description",
      selector: (row: DataProps) => row?.description,
      minWidth: "30%",
    },
    {
      name: "Type",
      selector: (row: { verification_status: JSX.Element }) =>
        row?.verification_status,
    },
    { name: "Action", selector: (row: { action: JSX.Element }) => row.action },
  ];

  const settingTableData: DataProps[] = [
    {
      name: "Print Prescription",
      description:
        "If the status is display, the lens prescription is display when creating the invoice. If the status is hide, the lens prescription is hide when creating the invoice",
      status: settingData.print_prescription,
      type: constant.SETTING_MODAL_TYPE.PRINT_PRESCRIPTION,
    },
    {
      name: "Order ready message",
      description: "-",
      status: settingData.order_ready_days,
      type: constant.SETTING_MODAL_TYPE.ORDER_READY_MESSAGE,
    },
    {
      name: "Default Delivery Date",
      description: "-",
      status: settingData.delivery_days,
      type: constant.SETTING_MODAL_TYPE.DELIVERY_DATE,
    },
  ];

  const handleEditButton = (type: string, deliveryDays: number) => {
    switch (type) {
      case constant.SETTING_MODAL_TYPE.PRINT_PRESCRIPTION:
        setShowModal(true);
        break;
      case constant.SETTING_MODAL_TYPE.ORDER_READY_MESSAGE:
        setModalType(type,deliveryDays);
        break;
      case constant.SETTING_MODAL_TYPE.DELIVERY_DATE:
        setModalType(type, deliveryDays);
        break;
      default:
        break;
    }
  };

  const handleEditButtonText = (type: string): string => {
    switch (type) {
      case constant.SETTING_MODAL_TYPE.PRINT_PRESCRIPTION:
        return "Show/Hide";
      case constant.SETTING_MODAL_TYPE.ORDER_READY_MESSAGE:
        return "Change Days";
      case constant.SETTING_MODAL_TYPE.DELIVERY_DATE:
        return "Change Days";
      default:
        return "";
    }
  };

  const handleVerificationStatus = ({
    type,
    status,
  }: HandleVerificationStatusProps): JSX.Element => {
    const renderMessage = (text: string, color: string): JSX.Element => (
      <p className={`color-${color} m-1 p-1`}>{text}</p>
    );
    switch (type) {
      case constant.SETTING_MODAL_TYPE.PRINT_PRESCRIPTION:
        return (
          status ? <div className="background-light-green border-product-radius">
            {renderMessage("Display", "green")}
          </div> : <div className="background-light-yellow border-product-radius">
            {renderMessage("Hide", "yellow")}
          </div>
        );
      case constant.SETTING_MODAL_TYPE.ORDER_READY_MESSAGE:
      case constant.SETTING_MODAL_TYPE.DELIVERY_DATE:
        return renderMessage(
          `${status} ${status === 1 ? "Day" : "Days"}`,
          "yellow"
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <Table
        columns={columns}
        AdditionalClassName="table-height-50"
        data={settingTableData?.map((item: any, index: number) => {
          return {
            ...item,
            index_number: index + 1,
            verification_status:
              handleVerificationStatus({
                type: item.type,
                status: item.status,
              }),
            action: (
              <>
                <div style={{ overflow: "unset", textOverflow: "unset" }}>
                  <Button
                    className="table-edit-buttons fs-14 fw-500"
                    onClick={() => handleEditButton(item.type, item?.status)}
                  >
                    <div className="d-flex align-items-center">
                      <FiEdit className="fs-20" />
                      <span className="ms-2 mb-0">
                        {handleEditButtonText(item.type)}
                      </span>
                    </div>
                  </Button>
                </div>
              </>
            ),
          };
        })}
      />
    </div>
  );
};

export default Index;
