import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../../components/organisms";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { useToasts } from "react-toast-notifications";
import service from "../../../../api/services";
import { HeaderHeading } from "../../../../Context";
import {handleReportDownload} from '../../../../constants/utils'
import { ExtendedQueryError, ReportStateProps } from "./interface";

const { EComOrdersReport } = organisms

const Index = () => {
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const { addToast } = useToasts();
    const [reportDate, setReportDate] = useState<ReportStateProps>({
        created_from: null,
        created_to: null,
        report_format:NaN
    })

    useEffect(() => {
        setHeader("Reports - E-Com Orders Report")
    }, [])

    const handleChange = (name: string, value:string | number) => {
        setReportDate({ ...reportDate, [name]: value })
    }

    const handleSubmit = async () => {
        try {
            const response = await service?.reportService?.getEcomOrderReport({ created_from: reportDate?.created_from, created_to: reportDate?.created_to })
            if(response?.status === 200){
                handleReportDownload(response?.data?.data, "sales-report", reportDate?.report_format, "Sales Report");
            }
        } catch (error) {
            console.error('error', error)
            const apiError = error as ExtendedQueryError
            addToast(apiError?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    return (
        <Wrapper>
            <EComOrdersReport
                reportData={reportDate}
                handleChange={(name: string, value: string | number) => handleChange(name, value)}
                handleSubmit={() => handleSubmit()} />
        </Wrapper>
    )
}

export default Index