import { useContext, useEffect, useState } from "react";
import organisms from "../../../components/organisms";
import service from "../../../api/services";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import { HeaderHeading } from "../../../Context";

const { LensDetails } = organisms;

const Index = () => {
    const [lensDetailsList, setLensDetailsList] = useState<any>();
    const { setHeader }: any = useContext(HeaderHeading);

    const getLensDetailList = async () => {
        try {
            const response = await service.lensDetails.list();
            setLensDetailsList(response.data);
        } catch (error) { }
    };
    useEffect(() => {
        setHeader("LensDetail");
        getLensDetailList();
    }, []);

    return (
        <Wrapper>
            <LensDetails
                productTypeListing={lensDetailsList}
                getLensDetailList={getLensDetailList}
            />
        </Wrapper>
    );
};

export default Index;
