import React, { useContext, useEffect, useState } from "react";
import organisms from "../../../components/organisms";
import molecules from "../../../components/molecules";
import { HeaderHeading } from "../../../Context";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";
import atoms from "../../../components/atoms";
import { useToasts } from "react-toast-notifications";
import { UpdateSettingProps } from "./interface.d";
import constant from "../../../constants/constant";

const { Setting } = organisms;
const { ConfirmationModal, CreateModal } = molecules;
const { Input } = atoms;

const Index = () => {
  const { addToast } = useToasts();
  const [inputDays, setInputDays] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState("");
  const { setHeader }: any = useContext(HeaderHeading);
  const [settingData, setSettingData] = useState<UpdateSettingProps>({
    order_ready_days: NaN,
    print_prescription: false,
    delivery_days: NaN,
  });

  const getSetting = async () => {
    try {
      let response = await service?.SettingService?.getList();
      setSettingData({
        order_ready_days: response?.data?.data?.default_order_ready_days,
        print_prescription: response?.data?.data?.print_prescription,
        delivery_days: response?.data?.data?.estimated_delivery_days,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSetting = async ({
    order_ready_days,
    print_prescription,
    delivery_days,
  }: UpdateSettingProps) => {
    let payload = {
      default_order_ready_days: order_ready_days,
      print_prescription: print_prescription,
      estimated_delivery_days: delivery_days,
    };
    try {
      let response = await service?.SettingService?.updateSetting(payload);
      if (response.status === 200 || response.status === 201) {
        addToast(response?.data?.message, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        getSetting();
        setShowModal(false);
        setModalType("");

      }
    } catch (error: any) {
      console.log(error);
      addToast(error?.response?.data?.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = e.target.value;
    if (value === "") {
      setInputDays(value);
      return;
    }
    const numericValue = parseInt(value, 10);
    if (numericValue < 4) {
      setInputDays(value);
    }
  };

  useEffect(() => {
    setHeader("Settings");
    getSetting();
  }, []);


  return (
    <Wrapper>
      <Setting
        setShowModal={setShowModal}
        settingData={settingData}
        setModalType={(type, deliveryDays) => {
          setModalType(type);
          setInputDays(deliveryDays);
        }}
      />
      {showModal && (
        <ConfirmationModal
          show={showModal}
          handleConfirmItem={() =>
            updateSetting({
              order_ready_days: settingData.order_ready_days,
              print_prescription: !settingData.print_prescription,
              delivery_days: settingData.delivery_days,
            })
          }
          onHide={() => setShowModal(!showModal)}
          message={`${!settingData.print_prescription ? "display" : "hide"
            } the lens prescription from the invoice`}
        />
      )}

      <CreateModal
        show={!!modalType} 
        header={"Enter Days"}
        submitBtnText={"Update"}
        handleSubmit={() => updateSetting({
          order_ready_days: modalType === constant.SETTING_MODAL_TYPE.ORDER_READY_MESSAGE ? inputDays : settingData.order_ready_days,
          print_prescription: settingData.print_prescription,
          delivery_days: modalType === constant.SETTING_MODAL_TYPE.DELIVERY_DATE ? inputDays : settingData.delivery_days,
        })}
        onHide={() => { setInputDays(0); setModalType(""); }} 
      >
        <Input
          type={"number"}
          name={"Days"}
          value={inputDays}
          onChange={(e:any) =>modalType === constant.SETTING_MODAL_TYPE.DELIVERY_DATE ? setInputDays(e.target.value) : handleInputChange(e)}
        />
      </CreateModal>

    </Wrapper>
  );
};

export default Index;
